import React from "react";
import SportsBook from "../../images/sportsbook.webp";
import RedTiger from "../../images/logos/redtiger.webp";
import RTG from "../../images/logos/rtg.webp";
import Spade from "../../images/logos/spade.webp";
import Jili from "../../images/logos/jili.webp";
import Netent from "../../images/logos/netent.webp";
import PGSoft from "../../images/logos/pg.webp";
import Playson from "../../images/logos/playson.webp";
import BoxKing from "../../images/boxking.webp";
import { featuredSlots } from "./file";

const dataList = [
  { id: 1, imgName: "Red Tiger", imgUrl: RedTiger },
  { id: 2, imgName: "Real Time Gaming", imgUrl: RTG },
  { id: 3, imgName: "Spadegaming", imgUrl: Spade },
  { id: 4, imgName: "Jili", imgUrl: Jili },
  { id: 5, imgName: "Netent", imgUrl: Netent },
  { id: 6, imgName: "PGSoft", imgUrl: PGSoft },
  { id: 7, imgName: "Playson", imgUrl: Playson },
];

const featuredGames = [
  { id: 1, imgName: "Box King", imgUrl: BoxKing },
  { id: 2, imgName: "Box King", imgUrl: BoxKing },
  { id: 3, imgName: "Box King", imgUrl: BoxKing },
  { id: 4, imgName: "Box King", imgUrl: BoxKing },
  { id: 5, imgName: "Box King", imgUrl: BoxKing },
  { id: 6, imgName: "Box King", imgUrl: BoxKing },
];

const featuredSlot = featuredSlots.map((data) => (
  <li key={data.id}>
    <img src={data.imgUrl} alt={data.imgName} />
  </li>
));
const featedItems = featuredGames.map((data) => (
  <li key={data.id}>
    <img src={data.imgUrl} alt={data.imgName} />
  </li>
));
const listItems = dataList.map((data) => (
  <li key={data.id}>
    <img src={data.imgUrl} alt={data.imgName} />
  </li>
));

export default function MainPage() {
  return (
    <>
      <section className="main">
        <img src={SportsBook} alt="Sportsbook" />
      </section>
      <section className="provider">
        <div class="h2-text-style">
          <span>Hottest Slots</span>
          <h2>Spin Now!</h2>
        </div>
        <section className="slot-provider-list">
          <ul>{listItems}</ul>
        </section>
        <div class="c2a-wrapper">
          <button class="yellow-btn">View More</button>
        </div>
      </section>
      <section className="featured-games">
        <ul>{featedItems}</ul>
      </section>
      <section className="featured-slot">
        <ul>{featuredSlot}</ul>
      </section>
    </>
  );
}
